import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

import ArrowIcon from '@/assets/images/arrow-white.svg';
import BlueFileIcon from '@/assets/images/blue-file-icon.svg';
import DeleteIcon from '@/assets/images/delete-icon-grey.svg';
import EllipsisIcon from '@/assets/images/ellipsis-icon.svg';
import ExternalLink from '@/assets/images/external-link.svg';
import MissingDocIcon from '@/assets/images/file.svg';
import FullScreenIcon from '@/assets/images/fullscreen-icon.svg';
import RedFileIcon from '@/assets/images/redfile.svg';
import RevertFullScreenIcon from '@/assets/images/reverse-fullscreen-icon.svg';
import Xicon from '@/assets/images/x-button-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { EmptySideBar } from '@/pages/overview/common/EmptySidebar';
import { LoaderWithText } from '@/pages/overview/common/LoaderWithText';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { SimpleDropdown } from '@/pages/overview/common/SimpleDropdown';
import { DropdownItems, useOverview } from '@/pages/overview/common/utils';
import { SelectedFolderDisplay } from '@/pages/overview/dataroom/sidebar/SelectedFolderDisplay';
import { ReferenceTotalTag } from '@/pages/pdf-viewer/common/ReferenceTotalTag';
import { trpcClient } from '@/utils/trpc';

interface RightSidePanelProps {
  pdfInteraction: boolean;
  hoveredHighlight?: HighlightArea | null;
  handleJumpToHighlight?: (highlights: number[]) => void;
  handleReferenceMouseOver?: (highlights: number[]) => void;
}

type HeightValuesType = {
  [key: string]: {
    [key: number]: {
      files: string;
      noFiles: string;
    };
  };
};

type RefHeightValuesType = {
  [key: string]: {
    [key: number]: {
      height: string;
    };
  };
};

export const RightSidePanel = ({
  pdfInteraction,
  hoveredHighlight,
  handleJumpToHighlight,
  handleReferenceMouseOver,
}: RightSidePanelProps) => {
  const { matter, setSelectedReferenceFocus, setSelectedRefHighlight, checkForTool } =
    useOverview();
  const {
    selectedFile,
    selectedFileDetails,
    selectSecondFileByName,
    checkedFiles,
    setCheckedFiles,
    selectedFoldersArray,
    updateFolderSelection,
    setIsCheckAllActive,
    changeFolderCheckStatus,
    setIsViewerOpen,
  } = useDataRoom();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isReferenceTableVisible, setIsReferenceTableVisible] = useState(true);
  const summaryDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedFile) {
      if (summaryDivRef.current) {
        summaryDivRef.current.scrollTop = 0;
      }
    }
  }, [selectedFile]);

  const deleteRefDoc = useCallback(
    (refDocId: string) => {
      trpcClient.dataRoom.deleteReferencedDocById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: refDocId,
      });
    },
    [matter.client.number, matter.number],
  );

  const markRefDocMissing = useCallback(
    (refDocId: string) => {
      trpcClient.dataRoom.updateMissingReferencedDocById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: refDocId,
      });
    },
    [matter.client.number, matter.number],
  );

  const hasMissingDocuments = useMemo(() => {
    return matter.finishedProcessingMissingDocs;
  }, [matter]);

  if (
    (selectedFoldersArray.length !== 0 || checkedFiles.length !== 0) &&
    selectedFile === undefined
  ) {
    return <SelectedFolderDisplay />;
  } else if (selectedFile === undefined) {
    return <EmptySideBar text="FILE" />;
  }

  const reformatEffectiveDate = (effectiveDate: string) => {
    if (effectiveDate !== 'N/A') {
      const newDate = new Date(effectiveDate);
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      newDate.setDate(newDate.getDate() + 1);
      const month = months[newDate.getMonth()];
      const day = newDate.getDate();
      const year = newDate.getFullYear();
      const formattedDate = `${month} ${day}, ${year}`;

      return formattedDate;
    }

    return 'N/A';
  };

  const handleReferenceClick = (highlight: number[]) => {
    if (pdfInteraction && handleJumpToHighlight) {
      handleJumpToHighlight(highlight);
    }

    return;
  };

  const handleMouseOver = (highlight: number[]) => {
    if (pdfInteraction && handleReferenceMouseOver) {
      handleReferenceMouseOver(highlight);
    }

    return;
  };

  const handleFileDeselect = () => {
    selectedFoldersArray.forEach((folder) => {
      updateFolderSelection(folder, true);
    });
    setCheckedFiles([]);
    setIsCheckAllActive(false);
    changeFolderCheckStatus(false);
  };

  const handleFoundReferenceClick = (
    secondDocumentName: string,
    referenceFocus: string,
    refHighlight: number[],
  ) => {
    setSelectedRefHighlight(refHighlight);
    selectSecondFileByName(secondDocumentName);
    setSelectedReferenceFocus(referenceFocus);
    setIsViewerOpen(true);
  };

  const calculateSummaryHeight = (referenceTotal: number, referenceByTotal: number) => {
    const numberOfFiles = checkedFiles.length;

    const higherRefTotal = Math.max(referenceTotal, referenceByTotal);

    const fileSelectionVisible = numberOfFiles > 0;

    if (!hasMissingDocuments) {
      if (pdfInteraction) {
        return 'h-[calc(100vh-328px)]';
      }
      if (fileSelectionVisible) {
        return 'h-[calc(100vh-410px)]';
      }
      if (!checkForTool('MISSING_DOCUMENT')) {
        return 'h-[calc(100vh-215px)]';
      }
      return 'h-[calc(100vh-350px)]';
    }

    if (!isReferenceTableVisible) {
      if (fileSelectionVisible) {
        if (pdfInteraction) {
          return 'h-[calc(100vh-305px)]';
        }
        return 'h-[calc(100vh-3px)]';
      }
      if (pdfInteraction) {
        return 'h-[calc(100vh-300px)]';
      }
      return 'h-[calc(100vh-260px)]';
    }

    const heightValues: HeightValuesType = {
      false: {
        0: { files: 'h-[calc(100vh-400px)]', noFiles: 'h-[calc(100vh-340px)]' },
        1: { files: 'h-[calc(100vh-400px)]', noFiles: 'h-[calc(100vh-340px)]' },
        2: { files: 'h-[calc(78vh-295px)]', noFiles: 'h-[calc(78vh-245px)]' },
        3: { files: 'h-[calc(78vh-390px)]', noFiles: 'h-[calc(78vh-330px)]' },
        4: { files: 'h-[calc(78vh-460px)]', noFiles: 'h-[calc(78vh-400px)]' },
      },
      true: {
        0: { files: 'h-[calc(100vh-405px)]', noFiles: 'h-[calc(100vh-390px)]' },
        1: { files: 'h-[calc(100vh-395px)]', noFiles: 'h-[calc(100vh-390px)]' },
        2: { files: 'h-[calc(78vh-295px)]', noFiles: 'h-[calc(78vh-275px)]' },
        3: { files: 'h-[calc(78vh-375px)]', noFiles: 'h-[calc(78vh-375px)]' },
        4: { files: 'h-[calc(78vh-450px)]', noFiles: 'h-[calc(78vh-450px)]' },
      },
    };

    const refKey = higherRefTotal >= 4 ? 4 : higherRefTotal;
    const key = fileSelectionVisible ? 'files' : 'noFiles';
    const pdfInteractionKey = pdfInteraction.toString();

    return heightValues[pdfInteractionKey][refKey][key];
  };

  const calculateReferenceTabHeight = (referenceTotal: number, referenceByTotal: number) => {
    const maxTotalReferences = Math.max(referenceTotal, referenceByTotal);

    if (!isReferenceTableVisible) {
      return 'h-0';
    }

    const heightValues: RefHeightValuesType = {
      false: {
        0: { height: 'h-[10vh]' },
        1: { height: 'h-[10vh]' },
        2: { height: 'h-[20vh]' },
        3: { height: 'h-[30vh]' },
        4: { height: 'h-[38vh]' },
      },
      true: {
        0: { height: 'h-[10vh]' },
        1: { height: 'h-[10vh]' },
        2: { height: 'h-[20vh]' },
        3: { height: 'h-[30vh]' },
        4: { height: 'h-[38vh]' },
      },
    };

    const refKey = maxTotalReferences >= 4 ? 4 : maxTotalReferences;
    const pdfInteractionKey = pdfInteraction.toString();

    return heightValues[pdfInteractionKey][refKey]['height'];
  };

  const getReferenceOptions = (referenceName: string, isMissing: boolean) => {
    const foundReference = selectedFile.mentions.find((mention) =>
      isMissing ? mention.title === referenceName : mention.targetDocument?.name === referenceName,
    );
    const referenceOptions: DropdownItems[] = [];
    if (!foundReference) {
      return referenceOptions;
    }
    if (isMissing) {
      referenceOptions.push({
        title: 'Remove Reference',
        icon: DeleteIcon,
        onClick: () => deleteRefDoc(foundReference.id),
      });
    } else {
      referenceOptions.push(
        {
          icon: MissingDocIcon,
          title: 'Mark as Missing',
          onClick: () => markRefDocMissing(foundReference.id),
        },
        {
          title: 'Remove Reference',
          icon: DeleteIcon,
          onClick: () => deleteRefDoc(foundReference.id),
        },
      );
    }

    return referenceOptions;
  };

  return (
    <>
      {selectedFileDetails && (
        <div
          className={`relative flex h-full w-[400px] shrink-0 flex-col bg-container-dark text-marveri-white`}
          data-testid="right-panel-sidebar"
        >
          {checkedFiles.length > 0 && !pdfInteraction && (
            <div className="flex cursor-default items-center justify-between border-b-2 border-light-border bg-container-light-gray p-4 font-bold text-marveri-white">
              <div className="flex gap-2">
                {selectedFoldersArray.length > 0 && (
                  <span>
                    {selectedFoldersArray.length} Folder{selectedFoldersArray.length > 1 ? 's' : ''}
                  </span>
                )}
                <span>
                  {checkedFiles.length} File{checkedFiles.length > 1 ? 's' : ''} Selected
                </span>
              </div>
              <MarveriIcon
                icon={Xicon}
                iconStyle="h-[1.2rem]"
                iconType="other"
                onClick={handleFileDeselect}
              />
            </div>
          )}
          <section className="flex min-h-[150px] flex-col justify-center border-b border-b-light-border">
            <div className="mx-4">
              <h3 className="my-2 text-[14px] font-medium text-marveri-light-silver">Type</h3>
              <p className="my-2 text-[14px] font-normal">{selectedFileDetails.type}</p>
            </div>
            <div className="mx-4">
              <h3 className=" text-[14px] font-medium text-marveri-light-silver">Effective Date</h3>
              <p className="my-2 text-[14px] font-normal">
                {reformatEffectiveDate(selectedFileDetails.effective_date)}
              </p>
            </div>
          </section>
          <div className={'flex h-full flex-col justify-between'}>
            <section>
              <div className="ml-4">
                <div className="flex items-center justify-between">
                  <h3 className="my-2 text-[14px] font-medium text-marveri-light-silver">
                    Summary
                  </h3>
                  {hasMissingDocuments && (
                    <MarveriIcon
                      icon={isReferenceTableVisible ? FullScreenIcon : RevertFullScreenIcon}
                      iconStyle="mr-4"
                      iconType="other"
                      onClick={() => setIsReferenceTableVisible((current) => !current)}
                    />
                  )}
                </div>
                <ScrollableDiv
                  containerStyle={`${calculateSummaryHeight(
                    selectedFileDetails.references.length,
                    selectedFileDetails.referenced_by.length,
                  )} ${
                    pdfInteraction ? 'min-h-[80px]' : 'min-h-[150px]'
                  } pr-2 text-[12px] font-normal`}
                  ref={summaryDivRef}
                >
                  <Markdown
                    remarkPlugins={[remarkBreaks]}
                    // @ts-expect-error markdown expects non-generic elements
                    components={{
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      h1: ({ node, ...props }) => (
                        <h2
                          className="font-['Inter'] text-[20px] font-semibold leading-tight text-[#e9e5ea]"
                          {...props}
                        />
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      h2: ({ node, ...props }) => (
                        <h2
                          className="mb-2 mt-3 font-['Inter'] text-[14px] font-medium leading-tight text-[#e9e5ea]"
                          {...props}
                        />
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      h3: ({ node, ...props }) => (
                        <h2
                          className="font-['Inter'] text-[12px] font-semibold leading-tight text-[#e9e5ea]"
                          {...props}
                        />
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      h4: ({ node, ...props }) => (
                        <h2
                          className="font-['Inter'] text-[12px] font-medium leading-tight text-[#e9e5ea]"
                          {...props}
                        />
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      p: ({ node, ...props }) => (
                        <p
                          className="font-['Inter'] text-[12px] font-normal leading-tight text-[#e9e5ea]"
                          {...props}
                        />
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      ul: ({ node, ...props }) => (
                        <ul
                          className="ml-5 list-disc font-['Inter'] text-[12px] font-normal leading-tight text-[#e9e5ea]"
                          {...props}
                        />
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      ol: ({ node, ...props }) => (
                        <ol
                          className="ml-5 list-decimal font-['Inter'] text-[12px] font-normal leading-tight text-[#e9e5ea]"
                          {...props}
                        />
                      ),
                    }}
                  >
                    {selectedFileDetails.summary}
                  </Markdown>
                </ScrollableDiv>
              </div>
            </section>
            <section className={``}>
              <TabGroup selectedIndex={activeTabIndex} onChange={setActiveTabIndex}>
                <TabList>
                  {hasMissingDocuments ? (
                    <div
                      className={`flex flex-row border-y border-marveri-light-silver text-center`}
                    >
                      <Tab className="flex w-2/5 items-center justify-center gap-2 border-b border-b-transparent py-2 text-[14px] font-semibold text-marveri-light-silver data-[headlessui-state=selected]:border-b data-[headlessui-state=selected]:border-b-marveri-gold data-[headlessui-state=selected]:text-marveri-gold">
                        References
                        {hasMissingDocuments && (
                          <ReferenceTotalTag
                            totalReferences={selectedFileDetails.references.length}
                            containerStyle={`${
                              activeTabIndex === 0
                                ? 'bg-[#715F32]'
                                : 'bg-[#868686] text-marveri-white'
                            }`}
                          />
                        )}
                      </Tab>
                      <Tab className="flex w-2/5 items-center justify-center gap-2 border-b border-b-transparent py-2 text-[14px] font-semibold text-marveri-light-silver data-[headlessui-state=selected]:border-b data-[headlessui-state=selected]:border-b-marveri-gold data-[headlessui-state=selected]:text-marveri-gold">
                        Referenced by
                        {hasMissingDocuments && (
                          <ReferenceTotalTag
                            totalReferences={selectedFileDetails.referenced_by.length}
                            containerStyle={`${
                              activeTabIndex === 1
                                ? 'bg-[#715F32]'
                                : 'bg-[#868686] text-marveri-white'
                            }`}
                          />
                        )}
                      </Tab>
                      <div
                        onClick={() => setIsReferenceTableVisible((prevState) => !prevState)}
                        className={`${
                          isReferenceTableVisible ? '-rotate-90' : 'rotate-90'
                        } m-auto flex size-[14px] translate-x-4 cursor-pointer items-center justify-center`}
                      >
                        <MarveriIcon icon={ArrowIcon} iconStyle="" iconType="other" />
                      </div>
                    </div>
                  ) : (
                    <>
                      {checkForTool('MISSING_DOCUMENT') && (
                        <LoaderWithText
                          text="Processing References"
                          containerStyle="flex w-fit h-fit m-auto items-center justify-center gap-2 rounded-[50px] bg-[#525252] px-4 py-[5px] my-4"
                        />
                      )}
                    </>
                  )}
                </TabList>
                {hasMissingDocuments && (
                  <ScrollableDiv
                    containerStyle={`${calculateReferenceTabHeight(
                      selectedFileDetails.references.length,
                      selectedFileDetails.referenced_by.length,
                    )}`}
                  >
                    <TabPanels>
                      <TabPanel>
                        {selectedFileDetails.references.length > 0 ? (
                          <div className="flex flex-col items-center">
                            {selectedFileDetails.references
                              .sort((a, b) => {
                                if (a.highlight[0] > b.highlight[0]) {
                                  return 1;
                                } else if (a.highlight[0] < b.highlight[0]) {
                                  return -1;
                                } else if (a.highlight[2] > b.highlight[2]) {
                                  return 1;
                                } else if (a.highlight[2] < b.highlight[2]) {
                                  return -1;
                                } else {
                                  return 0;
                                }
                              })
                              .map((ref) => (
                                <div
                                  key={ref.name}
                                  className="w-[90%] border-b border-b-marveri-light-silver py-2 first:pt-4"
                                >
                                  <div className="flex flex-col justify-between">
                                    <div className="flex justify-between text-[13px] font-bold">
                                      <span
                                        className={`flex  w-[90%] cursor-pointer items-center rounded-lg p-1 hover:bg-container-light-gray ${
                                          hoveredHighlight?.pageIndex === ref.highlight[0] &&
                                          hoveredHighlight?.top === ref.highlight[2]
                                            ? `${ref.missing ? 'bg-[#D02003]' : 'bg-[#0057D6]'}`
                                            : ''
                                        }`}
                                        onClick={() => handleReferenceClick(ref.highlight)}
                                        onDoubleClick={
                                          selectSecondFileByName
                                            ? () => selectSecondFileByName(ref.name)
                                            : () => {}
                                        }
                                        onMouseOver={() => handleMouseOver(ref.highlight)}
                                        onMouseOut={() => handleMouseOver([])}
                                      >
                                        {ref.generatedTitle}
                                      </span>
                                      <SimpleDropdown
                                        icon={EllipsisIcon}
                                        options={getReferenceOptions(ref.name, ref.missing)}
                                      />
                                    </div>
                                    <div className={`flex justify-between p-1`}>
                                      <div className={`flex min-w-[16px] items-center gap-2`}>
                                        <img
                                          src={ref.missing ? RedFileIcon : BlueFileIcon}
                                          className="shrink-0 grow-0"
                                        />
                                        {ref.missing ? (
                                          <div className="flex flex-row justify-center rounded-xl bg-[#4C2E29] px-3 py-1 text-[12px] text-[#D8573E]">
                                            Missing
                                          </div>
                                        ) : (
                                          <span className="w-4/5 text-[12px]">
                                            {ref.displayName}
                                          </span>
                                        )}
                                      </div>
                                      {!ref.missing ? (
                                        <div
                                          className="flex cursor-pointer flex-row items-center justify-center px-3 py-1 text-[12px] text-[#3D87F7]"
                                          onClick={() =>
                                            handleFoundReferenceClick(
                                              ref.name,
                                              'referenced',
                                              ref.highlight,
                                            )
                                          }
                                        >
                                          <span>Open</span>
                                          <img className="ml-1" src={ExternalLink} />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className="flex flex-col items-center py-2 text-center">
                            <h2 className="pb-2 text-[16px] font-semibold text-white">
                              No References Found
                            </h2>
                            <p className="w-[65%] text-[14px] font-normal text-marveri-light-silver">
                              This document doesn&apos;t appear to
                              <br />
                              reference any other documents
                            </p>
                          </div>
                        )}
                      </TabPanel>
                      <TabPanel>
                        {selectedFileDetails.referenced_by.length > 0 ? (
                          <div className="flex flex-col items-center">
                            {selectedFileDetails.referenced_by.map((ref) => (
                              <div
                                key={ref.name}
                                className="w-[90%] border-b border-b-marveri-light-silver py-2 first:pt-4"
                              >
                                <div className="flex flex-row items-center justify-between">
                                  <div className="flex gap-2">
                                    <img src={BlueFileIcon} />
                                    <div className="text-[13px]">{ref.displayName}</div>
                                  </div>
                                  <div
                                    className="flex cursor-pointer flex-row justify-center px-3 py-1 text-[14px] text-[#3D87F7]"
                                    onClick={() =>
                                      handleFoundReferenceClick(
                                        ref.name,
                                        'referencedBy',
                                        ref.highlight,
                                      )
                                    }
                                  >
                                    Open
                                    <img className="ml-1" src={ExternalLink} />
                                  </div>
                                </div>
                                <span className="ml-[1.4rem] text-left text-[13px]">
                                  {ref.generatedTitle}
                                </span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="flex flex-col items-center py-2 text-center">
                            <h2 className="pb-2 text-[16px] font-semibold text-white">
                              Not Referenced
                            </h2>
                            <p className="text-[14px] font-normal text-marveri-light-silver">
                              This document doesn&apos;t appear to be
                              <br />
                              referenced by any other documents
                            </p>
                          </div>
                        )}
                      </TabPanel>
                    </TabPanels>
                  </ScrollableDiv>
                )}
              </TabGroup>
            </section>
          </div>
        </div>
      )}
    </>
  );
};
