import ArrowIcon from '@/assets/images/arrow-right.svg';
import ExitIcon from '@/assets/images/x-button-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { useOverview } from '@/pages/overview/common/utils';

interface PdfDualHeaderProps {
  firstDocumentDisplayName: string;
  firstDocumentName?: string;
  secondDocumentDisplayName: string;
  secondDocumentName: string;
  selectSecondFile?: (fileName: string) => void;
  onClose: () => void;
}

export const PdfDualHeader = ({
  firstDocumentDisplayName,
  firstDocumentName,
  secondDocumentDisplayName,
  secondDocumentName,
  selectSecondFile,
  onClose,
}: PdfDualHeaderProps) => {
  const { setSelectedReferenceFocus } = useOverview();
  const { setSelectedFile } = useDataRoom();

  const handleViewDocumentDetails = (clickedFileName: string | undefined) => {
    if (clickedFileName) {
      setSelectedFile(clickedFileName);
      setSelectedReferenceFocus('referenced');
      if (selectSecondFile) {
        selectSecondFile('');
      }
    }
  };
  return (
    <div className="relative flex h-[70px] w-full items-center justify-between border-b border-b-light-border bg-black p-4 text-center text-marveri-white">
      <div className="flex max-w-[48%] items-center gap-4">
        <div
          className="flex h-[40px] w-[94px] cursor-pointer items-center justify-center gap-2 rounded-[8px] border-2 border-light-border bg-container-dark text-[14px] font-semibold text-marveri-light-silver hover:bg-container-hover"
          onClick={() => handleViewDocumentDetails(firstDocumentName)}
        >
          <img
            src={ArrowIcon}
            className="h-[18px] rotate-180"
            style={{
              filter:
                'invert(47%) sepia(36%) saturate(0%) hue-rotate(231deg) brightness(105%) contrast(83%)',
            }}
          />
          Back
        </div>
        <span className="truncate text-[14px]">{firstDocumentDisplayName}</span>
      </div>
      <div className="ml-4 flex max-w-[50%] items-center gap-4">
        <span className="truncate text-[14px]">{secondDocumentDisplayName}</span>
        <div
          className="flex h-[40px] w-[205px] shrink-0 cursor-pointer items-center justify-center rounded-[8px] bg-marveri-light-gold text-[14px] font-semibold text-black hover:bg-marveri-gold"
          onClick={() => handleViewDocumentDetails(secondDocumentName)}
        >
          View Document Details
          <img src={ArrowIcon} className="ml-4" />
        </div>
        <MarveriIcon
          icon={ExitIcon}
          iconStyle={'h-[20px] w-[20px]'}
          iconType="other"
          onClick={onClose}
        />
      </div>
    </div>
  );
};
