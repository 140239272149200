import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';

import DeleteIconRed from '@/assets/images/delete-icon-red.png';
import { trpcReact } from '@/utils/trpc';

interface DeleteDiligenceProps {
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (val: boolean) => void;
  setIsDeleteVisible: (val: boolean) => void;
  matterItem: {
    clientName: string | null;
    clientNumber: number;
    number: number;
  };
}

export const DeleteDiligence = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  setIsDeleteVisible,
  matterItem,
}: DeleteDiligenceProps) => {
  const removeDiligence = trpcReact.clientMatter.delete.useMutation();

  const confirmDiligenceDelete = () => {
    setIsDeleteModalOpen(false);
    removeDiligence.mutate({
      number: matterItem.number,
      clientNumber: matterItem.clientNumber,
    });
  };

  const cancelDiligenceDelete = () => {
    setIsDeleteModalOpen(false);
    setIsDeleteVisible(false);
  };

  return (
    <Dialog
      open={isDeleteModalOpen}
      onClose={() => cancelDiligenceDelete()}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      data-testid="delete-diligence-modal"
    >
      <DialogPanel className="flex h-fit w-[454px] flex-col items-center justify-center rounded-[5px] border-2 border-light-border bg-container-dark p-4">
        <img src={DeleteIconRed} className="m-4" />
        <DialogTitle className="w-[355px] text-center text-[25px] font-bold text-marveri-white">
          Are you sure you want to delete {matterItem.clientName}?
        </DialogTitle>
        <div className="m-6 flex w-4/5 justify-evenly">
          <button
            onClick={() => cancelDiligenceDelete()}
            className="h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
          >
            Cancel
          </button>
          <button
            onClick={confirmDiligenceDelete}
            className="h-[38px] w-[100px] rounded border border-dark-border bg-marveri-red text-marveri-white hover:bg-marveri-light-red"
          >
            Delete
          </button>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
