import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import DocumentListIcon from '@/assets/icons/document-set-icon.svg';
import ArrowIcon from '@/assets/icons/double-chevron-icon.svg';
import FinancingRightsIcon from '@/assets/icons/finance-rights-icon.svg';
import PlusIcon from '@/assets/icons/plus-icon.svg';
import RedlineIcon from '@/assets/icons/redline-icon.svg';
import { ClientMatterWithDetails, DataRoomClientFile, User } from '@/common/types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { toolList } from '@/pages/home/common/toolList';
import { UserHelpNav } from '@/pages/home/common/UserHelpNav';
import { ActiveTabLink } from '@/pages/overview/dataroom/content/common/ActiveTabLink';
import { token, trpcReact } from '@/utils/trpc';

export const ClientMatter = () => {
  const [matter, setMatter] = useState<ClientMatterWithDetails | undefined>();
  const [user, setUser] = useState<User | undefined>();

  const { clientNumber, matterNumber } = useParams();
  const [isQueryInProgress, setIsQueryInProgress] = useState(false);
  const [userMessage, setUserMessage] = useState<string>('');
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);
  const [selectedReferenceFocus, setSelectedReferenceFocus] = useState('referenced');
  const [selectedRefHighlight, setSelectedRefHighlight] = useState<number[]>([]);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [settingsAnchorTab, setSettingsAnchorTag] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const flags = useFlags();

  const toolPageLocation = `/${matter?.client.number}/${matter?.number}/tool-page`;
  const isUserOnToolPage = location.pathname === toolPageLocation;

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 1300) {
        setIsPanelCollapsed(true);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => {
      window.addEventListener('resize', handleWindowResize);
    };
  }, []);

  const convertParamToInt = useCallback(() => {
    if (clientNumber === undefined || matterNumber === undefined) {
      throw new Error('clientNumber and matterNumber must be defined.');
    }
    const clientNumberInt = parseInt(clientNumber);
    const matterNumberInt = parseInt(matterNumber);
    return [clientNumberInt, matterNumberInt];
  }, [clientNumber, matterNumber]);

  const [userClientNumber, userMatterNumber] = convertParamToInt();

  trpcReact.dataRoom.get.useQuery(
    {
      number: userMatterNumber,
      clientNumber: userClientNumber,
    },
    {
      onSuccess: setMatter,
    },
  );

  trpcReact.user.getCurrentUser.useQuery(undefined, { onSuccess: setUser });

  interface StringDatedDataRoomClientFile extends Omit<DataRoomClientFile, 'date'> {
    date: string | null;
  }
  const convertToDate = useCallback(
    (dataRoomFile: StringDatedDataRoomClientFile): DataRoomClientFile => {
      return {
        ...dataRoomFile,
        date: dataRoomFile.date ? new Date(dataRoomFile.date) : null,
      };
    },
    [],
  );

  trpcReact.user.onChange.useSubscription(
    {
      // The token must be defined for the subscription to be defined, but it won't be triggered until the token is set
      token: token ?? '',
    },
    {
      enabled: token !== undefined,
      onData({ data }) {
        const updatedUser = { ...data };
        setUser(updatedUser);
      },
    },
  );

  trpcReact.dataRoom.onChange.useSubscription(
    {
      // The token must be defined for the subscription to be defined, but it won't be triggered until the token is set
      token: token ?? '',
      number: userMatterNumber,
      clientNumber: userClientNumber,
    },
    {
      enabled: token !== undefined,
      onData({ data }) {
        // Convert the dates to Date objects since onData apparently converts them to strings
        const dataroomFiles = data.dataRoomFiles;
        // @ts-expect-error "Type instantiation is excessively deep and possibly infinite"
        const datedDataRoomFiles: DataRoomClientFile[] = dataroomFiles.map(convertToDate);

        const updatedMatter = {
          ...data,
          dataRoomFiles: datedDataRoomFiles,
          createdAt: new Date(data.createdAt),
          updatedAt: new Date(data.updatedAt),
          folderConfig: {
            ...data.folderConfig,
            emptyFolders: data.folderConfig?.emptyFolders || [],
            hiddenFolders: data.folderConfig?.hiddenFolders || [],
            folderAliases:
              data.folderConfig?.folderAliases.map((alias) => {
                return {
                  ...alias,
                  createdAt: new Date(alias.createdAt),
                  updatedAt: new Date(alias.updatedAt),
                };
              }) || [],
          },
          tools:
            data.tools.map((tool) => {
              return {
                ...tool,
                createdAt: new Date(tool.createdAt),
              };
            }) || [],
        };
        setMatter(updatedMatter);
      },
    },
  );

  const getSelectedTools = useMemo(() => {
    const overviewOptions = [
      {
        name: 'Document List',
        icon: DocumentListIcon,
        path: 'dataroom',
        toolIdentifier: '',
        toolTip: '',
      },
    ];
    const userSelectedTools = matter?.tools;
    const filteredTools = userSelectedTools?.filter(
      (tool) =>
        tool.toolType !== 'RENAME' &&
        tool.toolType !== 'ORGANIZE' &&
        tool.toolType !== 'REDLINE' &&
        (tool.toolType !== 'MISSING_SIGNATURE' || flags.missingDocColumnSelector),
    );
    const matchedTools = toolList.filter((tool) =>
      filteredTools?.some((filteredTool) => filteredTool.toolType === tool.toolIdentifier),
    );

    const hasRedlineTool = userSelectedTools?.some((tool) => tool.toolType === 'REDLINE');
    if (hasRedlineTool && matter?.redlines && matter.redlines.length > 0) {
      overviewOptions.push({
        name: 'Prior Redlines',
        icon: RedlineIcon,
        path: 'redline',
        toolIdentifier: 'REDLINE',
        toolTip: '',
      });
    }

    const newOverviewOptions = [...overviewOptions, ...matchedTools];

    return newOverviewOptions;
  }, [flags.missingDocColumnSelector, matter?.redlines, matter?.tools]);

  const handleToolPageClick = useCallback(() => {
    if (isUserOnToolPage) {
      navigate(-1);
    } else {
      navigate(`/${matter?.client.number}/${matter?.number}/tool-page`);
    }
  }, [isUserOnToolPage, matter?.client.number, matter?.number, navigate]);

  const checkForTool = useCallback(
    (toolIdentifier: string) => {
      const doesToolExist = matter?.tools.some((tool) => tool.toolType === toolIdentifier);
      return doesToolExist;
    },
    [matter?.tools],
  );

  const toolProgress = useMemo(() => {
    return (
      startTime: Date | undefined,
      finishTime: Date | undefined,
      currentDate: Date | undefined,
    ) => {
      if (startTime && finishTime && currentDate) {
        const start = startTime.getTime();
        const finish = finishTime.getTime();
        const currentTime = currentDate.getTime();

        const progress = ((currentTime - start) / (finish - start)) * 100.0;
        return Math.min(progress, 95);
      }

      return 0;
    };
  }, []);

  const filesAreProcessing = useMemo(() => {
    return !matter?.number || matter?.fileUploadPercentComplete != 100;
  }, [matter?.fileUploadPercentComplete, matter?.number]);

  const redlinesAreProcessing = useMemo(() => {
    return (
      matter?.dataRoomFiles.length === 0 ||
      matter?.dataRoomFiles.some((file) => !file.hasComputedEmbedding)
    );
  }, [matter?.dataRoomFiles]);

  const referencesAreProcessing = useMemo(() => {
    return !matter?.finishedProcessingMissingDocs;
  }, [matter]);

  const signaturesAreProcessing = useMemo(() => {
    return (
      matter?.dataRoomFiles.length === 0 ||
      matter?.dataRoomFiles.some((file) => file.missingSignature === null)
    );
  }, [matter?.dataRoomFiles]);

  const financingsAreProcessing = useMemo(() => {
    return !matter?.finishedProcessingVetoData;
  }, [matter?.finishedProcessingVetoData]);

  const clausesAreProcessing = useMemo(() => {
    return !matter?.finishedProcessingMAClausesData;
  }, [matter]);

  const oldClausesAreProcessing = useMemo(() => {
    return !matter?.finishedProcessingMAClausesDataOld;
  }, [matter]);

  const PER_FILE = useMemo(() => {
    return matter?.dataRoomFiles.length || 1;
  }, [matter?.dataRoomFiles.length]);

  const START_PER_TOOL = useMemo(() => {
    const tools = matter?.tools || [];
    const startPerTool = {} as { [key: string]: Date };
    tools.forEach((tool) => {
      startPerTool[tool.toolType] = new Date(tool.createdAt);
    });
    return startPerTool;
  }, [matter?.tools]);

  const FINISH_TIME_PER_TOOL = useMemo(() => {
    const ONE_MINUTE = 1000 * 60;

    const SECONDS_PER_TOOL = {
      REDLINE: 5,
      RENAME: 10,
      ORGANIZE: 10,
      MISSING_DOCUMENT: 19,
      MISSING_SIGNATURE: 23,
      CONTRACT_CLAUSES: 22,
      CONTRACT_CLAUSES_OLD: 22,
      FINANCE_REVIEW: 15,
    } as { [key: string]: number };

    const tools = matter?.tools || [];
    const finishPerTool = {} as { [key: string]: Date };
    tools.forEach((tool) => {
      finishPerTool[tool.toolType] = new Date(
        (START_PER_TOOL[tool.toolType]?.getTime() || 0) +
          ONE_MINUTE +
          SECONDS_PER_TOOL[tool.toolType] * 1000 * PER_FILE,
      );
    });
    return finishPerTool;
  }, [PER_FILE, START_PER_TOOL, matter?.tools]);

  const getToolProcessing = useCallback(
    (toolIdentifier: string) => {
      switch (toolIdentifier) {
        case 'REDLINE':
          return {
            startTime: START_PER_TOOL[toolIdentifier],
            finishTime: FINISH_TIME_PER_TOOL[toolIdentifier],
            complete: !redlinesAreProcessing,
          };
        case 'RENAME':
          return {
            startTime: START_PER_TOOL[toolIdentifier],
            finishTime: FINISH_TIME_PER_TOOL[toolIdentifier],
            complete: !filesAreProcessing,
          };
        case 'ORGANIZE':
          return {
            startTime: START_PER_TOOL[toolIdentifier],
            finishTime: FINISH_TIME_PER_TOOL[toolIdentifier],
            complete: !filesAreProcessing,
          };
        case 'MISSING_DOCUMENT':
          return {
            startTime: START_PER_TOOL[toolIdentifier],
            finishTime: FINISH_TIME_PER_TOOL[toolIdentifier],
            complete: !referencesAreProcessing,
          };
        case 'MISSING_SIGNATURE':
          return {
            startTime: START_PER_TOOL[toolIdentifier],
            finishTime: FINISH_TIME_PER_TOOL[toolIdentifier],
            complete: !signaturesAreProcessing,
          };
        case 'FINANCE_REVIEW':
          return {
            startTime: START_PER_TOOL[toolIdentifier],
            finishTime: FINISH_TIME_PER_TOOL[toolIdentifier],
            complete: !financingsAreProcessing,
          };
        case 'CONTRACT_CLAUSES':
          return {
            startTime: START_PER_TOOL[toolIdentifier],
            finishTime: FINISH_TIME_PER_TOOL[toolIdentifier],
            complete: !clausesAreProcessing,
          };
        case 'CONTRACT_CLAUSES_OLD':
          return {
            startTime: START_PER_TOOL[toolIdentifier],
            finishTime: FINISH_TIME_PER_TOOL[toolIdentifier],
            complete: !oldClausesAreProcessing,
          };
        default:
          return { startTime: undefined, finishTime: undefined, complete: true };
      }
    },
    [
      FINISH_TIME_PER_TOOL,
      START_PER_TOOL,
      clausesAreProcessing,
      filesAreProcessing,
      financingsAreProcessing,
      oldClausesAreProcessing,
      redlinesAreProcessing,
      referencesAreProcessing,
      signaturesAreProcessing,
    ],
  );

  return (
    <div className="flex size-full bg-container-default py-[12px] pr-[12px]">
      <div
        className={`${
          isPanelCollapsed ? 'w-[70px]' : 'w-[264px]'
        } shrink-0  px-[12px] transition-width duration-300 ease-in-out`}
      >
        <div className={`${isPanelCollapsed && ' items-center'} flex h-full flex-col`}>
          <Link
            to={'/'}
            className="translate-y-[-4px] cursor-pointer px-[4px] pt-[4px] font-marveri-logo text-[20px] font-bold uppercase tracking-widest  text-marveri-white opacity-60 hover:opacity-100"
          >
            {isPanelCollapsed ? 'MV' : 'Marveri'}
          </Link>
          <div className="flex w-full">
            <div
              className={`${
                isPanelCollapsed ? 'm-auto' : 'ml-auto'
              } mb-[28px] mt-[23px] flex h-[28px] w-[36px] cursor-pointer items-center justify-center rounded-[8px] hover:bg-N700`}
              onClick={() => setIsPanelCollapsed((currentState) => !currentState)}
            >
              <img
                src={ArrowIcon}
                className={`${isPanelCollapsed ? 'rotate-180' : ''} transition-transform`}
              />
            </div>
          </div>
          <div
            className={`${isPanelCollapsed ? 'w-[40px]' : 'w-[88px]'} mb-[14px] flex h-[40px] cursor-pointer  items-center gap-[4px] rounded-[8px] bg-marveri-white px-[12px] py-[10px] font-medium hover:bg-[#F8F5EE]`}
            onClick={handleToolPageClick}
          >
            <img
              src={PlusIcon}
              className={`${isUserOnToolPage && 'rotate-45'} shrink-0 transition-all`}
            />
            {!isPanelCollapsed && <span>Tools</span>}
          </div>
          <div
            className={`${isPanelCollapsed && 'items-center'} flex h-[88%] flex-col justify-between`}
          >
            <div>
              {getSelectedTools.map((tool, index) => {
                return (
                  <ActiveTabLink
                    matter={matter}
                    toolIdentifier={tool.toolIdentifier}
                    to={
                      tool.toolIdentifier === 'MISSING_SIGNATURE' && flags.missingDocColumnSelector
                        ? 'missing-signatures-debug'
                        : tool.path
                    }
                    feature={true}
                    isPanelCollapsed={isPanelCollapsed}
                    key={index}
                    toolProgress={toolProgress}
                    getToolProcessing={getToolProcessing}
                  >
                    <div className="flex gap-[13px]">
                      <img src={tool.icon} className="shrink-0" />
                      {!isPanelCollapsed && (
                        <span className="font-medium">
                          {tool.toolIdentifier === 'MISSING_SIGNATURE'
                            ? 'Missing Signatures - DEBUG'
                            : tool.toolIdentifier === 'MISSING_DOCUMENT'
                              ? 'Missing Documents'
                              : tool.name}
                        </span>
                      )}
                    </div>
                  </ActiveTabLink>
                );
              })}
              {flags.missingDocColumnSelector && (
                <ActiveTabLink
                  matter={matter}
                  toolIdentifier="FINANCE_REVIEW"
                  to="veto-debug"
                  feature={true}
                  isPanelCollapsed={isPanelCollapsed}
                  key={'veto-debug'}
                  toolProgress={toolProgress}
                  getToolProcessing={getToolProcessing}
                >
                  <div className="flex gap-[13px]">
                    <img src={FinancingRightsIcon} className="shrink-0" />
                    {!isPanelCollapsed && (
                      <span className="font-medium">Financing Rights Review - DEBUG</span>
                    )}
                  </div>
                </ActiveTabLink>
              )}
            </div>
            {user && (
              <div className="px-[7px]">
                <UserHelpNav
                  user={user}
                  isPanelCollapsed={isPanelCollapsed}
                  isSettingsModalOpen={isSettingsModalOpen}
                  setIsSettingsModalOpen={setIsSettingsModalOpen}
                  anchorTab={settingsAnchorTab}
                  setSettingsAnchorTag={setSettingsAnchorTag}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex grow rounded-[12px] bg-marveri-background">
        {matter ? (
          <Outlet
            context={{
              matter,
              isQueryInProgress,
              setIsQueryInProgress,
              userMessage,
              setUserMessage,
              setIsPanelCollapsed,
              isPanelCollapsed,
              selectedReferenceFocus,
              setSelectedReferenceFocus,
              selectedRefHighlight,
              setSelectedRefHighlight,
              toolProgress,
              getToolProcessing,
              checkForTool,
              isSettingsModalOpen,
              setIsSettingsModalOpen,
              settingsAnchorTab,
              setSettingsAnchorTag,
              user,
            }}
          />
        ) : (
          <div className="flex size-full items-center justify-center">
            <LoadingSpinner size={20} />
          </div>
        )}
      </div>
    </div>
  );
};
